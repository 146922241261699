import React, { useState, ChangeEvent, FormEvent } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import useStateAlertStore from '../store/stateAlertStore';
import { aggiungiFonte, eliminaFonte, modificaFonte } from '@api/apiFonti';
interface FormProps {
    fonte: Fonte | null;
    operazione: 'modifica' | 'aggiungi' | 'elimina';
    closeModal: () => void;
}

const FonteForm: React.FC<FormProps> = ({ fonte, operazione, closeModal }) => {
    const [formData, setFormData] = useState<Fonte>(fonte ||
    {
        id: 0,
        nome: ""
    });

    const openAlert = useStateAlertStore(state => state.showAlert)


    const handleOperazione = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        switch (operazione) {
            case 'modifica':
                if (fonte?.id)
                    modificaFonte(fonte?.id, formData).then(data => {
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                            closeModal()
                        }})
                    });
                break;
            case 'aggiungi':
                aggiungiFonte(formData).then(data => {
                    openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                        closeModal()
                    }})
                });
                break;
            case 'elimina':
                if (fonte?.id)
                    eliminaFonte(fonte?.id).then(data => {
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                            closeModal()
                        }})
                    })
                break;
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name as string]: value,
        }));
    };

    useEffect(function reloadFonti() {
        if (fonte) {
            setFormData(fonte);
        }
    }, [fonte])
    const isDisabled = operazione === 'elimina';
    return (
        <>
            <Box component="form" autoComplete="off" onSubmit={handleOperazione} sx={{ padding: '1em' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField id="nome" required label="Nome" variant="outlined" inputProps={{ maxLength: 20 }} name="nome" value={formData.nome} onChange={handleChange} fullWidth disabled={isDisabled} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" fullWidth>Conferma  {operazione}</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FonteForm;
