import {Chip} from '@mui/material'
import useLeadsStore from '@components/store/leadsStore'

function cambiaLabel(chiave: string): string{
    switch(chiave){
        case 'id': {
            return "Codice Lead"
        }
        case 'fonteId': {
            return "Fonte"
        }
        case 'nomeCognome': {
            return "Nome e Cognome"
        }
        case 'venditoreId': {
            return "Venditore"
        }
        case 'azienda': {
            return "Azienda"
        }
        case 'situazione': {
            return "Situazione"
        }
        case 'cellulare': {
            return "Cellulare"
        }
        case 'email': {
            return "E-mail"
        }
        default: {
            return ""
        }
        
    }
}

const FiltriAttivi: React.FC = () => {
    const {selectedFilter, removeSingleFilter} = useLeadsStore(state => state);
    return (
        <>
            { Object.entries(selectedFilter).map(([chiave,valore]) => (
                <Chip
                    label={cambiaLabel(chiave)}
                    onDelete={() => removeSingleFilter(chiave as keyof TFiltri)}
                    sx={{marginLeft: ".5em", marginRight: ".5em"}}
                />
            ))}
        </>
    )
}


export default FiltriAttivi;
