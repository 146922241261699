import {create} from 'zustand';
import { TPopUp, TPopUpStore } from '../../types/confermaPopUp';


const useConfermaPopUpStore = create<TPopUpStore>((set,get) => ({
    titolo: "",
    domanda: "",    
    id: null,
    callback: null,
    isOpen: false,
    openDialog: (ops: TPopUp) => set({...ops, isOpen:true}),
    closeDialog: () => set({ isOpen: false, id: null, callback: null }),
    confirmDialog: () => set((state) => {
      if (state.callback && state.id !== null) state.callback(state.id);
      return { isOpen: false, id: null, callback: null };
    })
  }));
  
  export default useConfermaPopUpStore;