import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import useConfermaPopUpStore from '@components/store/confermaPopUpStore';


const ConfermaPopUp = () => {
    const {isOpen, titolo, domanda,closeDialog, id, confirmDialog} = useConfermaPopUpStore(state => state);
    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{titolo}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {domanda}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    No
                </Button>
                <Button onClick={confirmDialog} color="primary" autoFocus>
                    Sì
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ConfermaPopUp