import { useState, useEffect } from 'react';
import FonteForm from './FonteForm';

interface Parametri {
    id: number | null;
    operazione: 'modifica' | 'aggiungi' | 'elimina';
    closeModal: () => void;
}

const apiUrl = process.env.REACT_APP_URL_API;
const DashboardFonte: React.FC<Parametri> = ({id, operazione, closeModal}) =>{
    const [selectedFonte, setSelectedFonte] = useState<Fonte | null>(null);
    
    const fetchFonte =async () => {
        fetch(apiUrl + `/fonte/${id}`,{
            headers: {
              'Authorization': `Basic ${btoa("labyuser" + ":" + "labytech")}` // Aggiungi l'header Authorization
            }
          })
            .then(response => response.json())
            .then(data => setSelectedFonte(data))
            .catch(error => console.error('Errore:', error));
    }

    useEffect(function init(){
        if(id){
          fetchFonte();
        }
    }, [id]);

    return (
        <>
            <div>
                <FonteForm fonte={selectedFonte} operazione={operazione} closeModal={closeModal}></FonteForm>
            </div>
        </>
    )
}

  export default DashboardFonte;
