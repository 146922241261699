import { useState, useEffect } from 'react';
import { Typography, Button, Stack, Box, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardStato from '@components/Stati/DashboardStato';
import Aside from '@components/object/Aside';
import { fetchStati, prendiStato } from '@api/apiStati';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    {
        field: 'colore', headerName: 'Colore', width: 150,
        renderCell: (params) => (
            <>
                <Box sx={{ bgcolor: params.row?.colore, width: 40, height: 20 }} />
            </>
        )
    },
    {
        field: 'isFixed', headerName: 'Pre-impostato', width: 150, valueGetter: (value, row) => {
            if (!row.isFixed) {
                return "";
            } else {
                return "Sì";
            }
        }
    }
];

const Stati: React.FC = () => {
    const [stati, setStati] = useState<Stato[]>([]);
    const [selectedID, setSelectedID] = useState(null);
    const [idRigaSelezionata, setidRigaSelezionata] = useState(null);
    const [bottoniDisattivati, setBottoniDisattivati] = useState(true);
    const [operazione, setOperazione] = useState<TOperazione>({ openModal: false });

    const handleClickRiga: GridEventListener<'rowClick'> = (rigaSelezionata) => {
        const idRiga = rigaSelezionata.row.id;
        setSelectedID(idRiga);
        setidRigaSelezionata(idRiga);

        //Gli stati preimpostati non possono essere ne modificati ne eliminati
        const checkFixedStato = stati.filter((stato => stato.id == idRiga))[0].isFixed;
        if (!checkFixedStato)
            setBottoniDisattivati(false);
        else
            setBottoniDisattivati(true);
    };

    const openCreateModal = () => {
        setSelectedID(null); //sono in aggiungi quindi sicuro non ho l'id
        setOperazione({ openModal: true, operazione: 'aggiungi' })
    }

    const openEditModal = () => {
        setOperazione({ openModal: true, operazione: 'modifica' });
    }

    const openDeleteModal = () => {
        setOperazione({ openModal: true, operazione: 'elimina' });
    }

    useEffect(function initListaStati() {
        fetchStati().then(data => setStati(data));
    }, [operazione.openModal]);


    const handleApriDrawer = () => {
        setOperazione({ openModal: true });
    };

    const closeModal = () => {
        setOperazione({ openModal: false });
        setSelectedID(idRigaSelezionata);
    };

    return (
        <>
            <Typography variant='h3'>Lista degli stati</Typography>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ m: 5 }}>
                <Button variant="contained" onClick={openCreateModal}> <NoteAddIcon /></Button>
                <Button variant="contained" onClick={openEditModal} disabled={bottoniDisattivati}> <SettingsIcon /></Button>
                <Button variant="contained" onClick={openDeleteModal} disabled={bottoniDisattivati} > <DeleteIcon /></Button>

            </Stack>

            <Paper elevation={0} >
                <div style={{ width: '100%' }}>
                    <DataGrid rows={stati} columns={columns} onRowClick={handleClickRiga} localeText={itIT.components.MuiDataGrid.defaultProps.localeText} />
                </div>
            </Paper>

            {operazione?.operazione &&
                <Aside zIndex={1202} direction='right' operazione={operazione?.operazione} onOpen={handleApriDrawer} onClose={closeModal} isOpen={operazione.openModal} modello='stato'>
                    <DashboardStato id={selectedID} operazione={operazione?.operazione} closeModal={closeModal} />
                </Aside>
            }
        </>
    )
}

export default Stati;