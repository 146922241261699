
import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useStateAlertStore from '../store/stateAlertStore';

const DEFAULT_DURATION = 3000;


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StateAlert = () =>{
    const {message, severity, duration, isOpen} = useStateAlertStore(state => state);

    return (
        <Snackbar open={isOpen} autoHideDuration={duration ?? DEFAULT_DURATION}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}
export default StateAlert;



