import { Box, Grid, Typography, Button, FormControlLabel, DialogActions, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { aggiungiEventoCalendarioGoogle } from '@utils/calendario/aggiungiEventoCalendarioGoogle';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';

interface Props {
    event: ProcessedEvent;
}

const clickTrattative = (trattativaID: number) => {
    window.open("/gestione-trattative/" + trattativaID, "_blank");
};

const RiepigoloEvento: React.FC<Props> = ({ event }: Props) => {
    return (
        <>
            <Tooltip title="Apri storia trattative">
                <Button variant="contained" onClick={() => clickTrattative(event.leadID)} sx={{ marginRight: 1 }}> <VisibilityIcon /></Button>
            </Tooltip>
            <Tooltip title="Aggiungi evento a Google Calendar">
                <Button variant="contained" onClick={() => aggiungiEventoCalendarioGoogle(event)}> Aggiungi a Google Calendar</Button>
            </Tooltip>
            <p><b>Nota:</b> <br /> {event.nota || "Nessuna..."}</p>
            <p><b>Cellulare:</b> {event.cellulare}</p>
            <p><b>Completato:</b> {event.completato ? "Sì" : "No"}</p>
        </>
    )
}
export default RiepigoloEvento;
