import * as React from 'react';
import { Box, SwipeableDrawer, AppBar, Toolbar, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    children: React.ReactNode;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    zIndex: number;
    direction: 'right' | 'left' | 'top' | 'bottom';
    operazione: string;
    modello: string
    
}

export default function Aside(props: Props) {
    const { children, isOpen, onOpen, onClose, zIndex, direction, operazione, modello} = props;
    return (
        <SwipeableDrawer open={isOpen} onClose={onClose} onOpen={onOpen} anchor={direction} style={{ zIndex: zIndex }}>
        
          <Box sx={{ width: 600 }} role="presentation">
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, '&::first-letter': { textTransform: 'capitalize'} }}>
                  {operazione} {modello}
                </Typography>
                <Button color="inherit" onClick={onClose}><CloseIcon></CloseIcon></Button>
              </Toolbar>
            </AppBar>
            {children}
          </Box>
        
      </SwipeableDrawer>
    )
}