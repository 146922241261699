import {create} from 'zustand';
import {TLeadsStore} from '../../types/Leads';
import { fetchLeads, fetchLead, aggiungiLead, modificaLead, eliminaLead } from '@api/apiLeads';

const useLeadsStore = create<TLeadsStore>((set, get) =>({
    leads: [] as Array<Lead>,
    filteredLeads: [] as Array<Lead>,
    fetchLeads: async () =>{
        await fetchLeads()
            .then((data) =>{
                set({
                    leads: data,
                    filteredLeads: data,
                });
                get().makeFilter();
            })
            .catch()
        
    }, 
    fetchLead: async (idLead: number): Promise<Lead> =>{
        const response: Lead = await fetchLead(idLead)
            .then((data) =>{
                return data;
            })
            .catch()
        return new Promise(resolve => resolve(response));
    }, 
    addLead: async (lead: Lead): Promise<TResponseStatus> => {
        const response: TResponseStatus = await aggiungiLead(lead)
        if(!response.error){
            get().fetchLeads();
            get().makeFilter();
        }
        return new Promise(resolve => resolve(response));
    },
    updateLead: async (leadID: number, lead: Lead): Promise<TResponseStatus> => {
        const response: TResponseStatus = await modificaLead(leadID, lead)
        if(!response.error){
            get().fetchLeads();
            get().makeFilter();
        }
        return new Promise(resolve => resolve(response));
    },
    deleteLead: async (leadID: number): Promise<TResponseStatus> =>{
        const response: TResponseStatus = await eliminaLead(leadID)
        if (!response.error) {
            const tempLead = get().leads.filter(lead => lead.id != leadID);
            set({ leads: tempLead })
            get().makeFilter()
        }
        return new Promise(resolve => resolve(response));
    },
    selectedFilter: {},
    makeFilter: () =>{
        if (get().selectedFilter) {
            const datiFiltrati = get().leads?.filter((lead: Lead) => {
                return Object.entries(get().selectedFilter).every(([key, value])=>{
                    switch(key){
                        case 'id': {
                            return lead?.id == value
                        }
                        case 'fonteId': {
                            return lead.fonte?.id == value
                        }
                        case 'nomeCognome': {
                            return lead.nomeCognome.toLowerCase().includes(value.toString().toLowerCase())
                        }
                        case 'venditoreId': {
                            return lead.utenteId == value
                        }
                        case 'azienda': {
                            return lead.azienda.toLowerCase().includes(value.toString().toLowerCase())
                        }
                        case 'situazione': {
                            return lead?.situazione == value
                        }
                        case 'cellulare': {
                            const numeroPulito = lead.cellulare.replace(/\D/g, '');  // Rimuove tutto tranne le cifre
                            return numeroPulito.startsWith(value.toString());
                        }
                        case 'email': {
                            return lead.email.toLowerCase().includes(value.toString().toLowerCase())
                        }
                    }
                })
            });
            set({filteredLeads: datiFiltrati})
        }
    },
    setSelectedFilter: (nuoviFiltri: TFiltri) =>{
        set({selectedFilter: nuoviFiltri});
        get().makeFilter();
    },
    removeSingleFilter:(proprieta: keyof TFiltri) =>{
        const { [proprieta]: _, ...newSelectedFilter } = get().selectedFilter;
        get().setSelectedFilter(newSelectedFilter);
    },
    removeAllFilter: () =>{
        get().setSelectedFilter({})
    },
    selectedID: 0,
    setselectedID: (id: number) =>{
        set({selectedID: id});
    },
}));

export default useLeadsStore;
