import '@styles/App.css';
import { Routes, Route } from "react-router-dom"
import Stati from '@pages/Stati';
import Leads from '@pages/Leads';
import Login from '@pages/Login';
import Home from '@pages/Home';
import GestioneUtenti from '@pages/GestioneUtenti';
import Fonti from '@pages/Fonti';
import Register from '@pages/Register';
import DashboardTrattativa from '@pages/DashboardTrattativa';
import RootLayout from './layout';
import StateAlert from '@components/object/StateAlert';
import ConfermaPopUp from '@components/object/ConfermaPopUp';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
      <RootLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stati" element={<Stati />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/fonti" element={<Fonti />} />
          <Route path="/register" element={<Register />} />
          <Route path="/gestione-utenti" element={<GestioneUtenti />} />
          <Route path="/gestione-trattative/:selectedID?" element={<DashboardTrattativa />} />
        </Routes>
      </RootLayout>
      <StateAlert/>
      <ConfermaPopUp/>
    </div>
  );
}

export default App;
