import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

interface LabelGGProps {
  inputDate: string; 
}

const LabelGG: React.FC<LabelGGProps> = ({ inputDate }) => {
  const [daysDifference, setDaysDifference] = useState<number | null>(null);

  useEffect(() => {
      if (inputDate) {
          let inputDateTime = new Date(inputDate);
          let currentDate = new Date();
          inputDateTime.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);

          const differenceInMilliseconds = currentDate.getTime() - inputDateTime.getTime();
          const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
          setDaysDifference(differenceInDays);

      }else{
        setDaysDifference(null)
      }
  }, [inputDate]);

  return (
    <div>
      {daysDifference !== null ? (
        <Chip label={(() =>{
            if(daysDifference == 0){
                return 'Oggi';
            }else{
                return daysDifference + " gg";
            }
        })()}  sx={{backgroundColor: daysDifference > 2 ? '#F1C0C0': undefined, fontWeight: 'bold'}}/>
      ) : (
        <Chip label="Mai" />
      )}
    </div>
  );
};

export default LabelGG;
