import checkResponseApi from "./apiUtils";

const apiUrl = process.env.REACT_APP_URL_API;


export const fetchFonti = async (): Promise<Fonte[]> => {
    const response = await fetch(`${apiUrl}/fonte`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il recupero dati');
    }
    return await response.json();
};
export const aggiungiFonte = async (formData: Fonte): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/fonte`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });

    return checkResponseApi(response, "Fonte aggiunta!");

};
export const modificaFonte = async (idFonte: number, formData: Fonte): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/fonte/${idFonte}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });
    
    return checkResponseApi(response, "Fonte modificata!");
};

export const eliminaFonte = async (idFonte: number): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/fonte/${idFonte}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return checkResponseApi(response, "Fonte eliminata!");
};