import React, { useState } from 'react';
import {
    Avatar, Button, CssBaseline,
    TextField, FormControlLabel,
    Checkbox, Link, Box, Container, Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from "@api/apiLoginService";
import packageJson from '../../package.json';

function Copyright(props: any) {
    return (
        <>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://www.gestionalelabycar.com/">
                    LABY TECHNOLOGIES LTD
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
               Versione: {packageJson.version}
            </Typography>
        </>
    );
}
const themeOptions = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#C54040',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: 'white',
        },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                },
            },
        },
    },

});

function Login() {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberme, setRememberme] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "rememberme") setRememberme(e.target.checked);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Compilare tutti i campi");
        } else {
            setError("");
            let remberMeValue = ""
            if (rememberme == true)
                remberMeValue = "useCookies=true";
            else
                remberMeValue = "useSessionCookies=true";
            login(remberMeValue, email, password).then((data) => {
                if (data.ok) {
                    window.location.href = '/';
                } else {
                    if (data.status == 401)
                        setError("Errore in fase di login: email e/o passord errata");
                    else
                        setError("Errore in fase di login");
                }
            });
        }
    };

    return (
        <ThemeProvider theme={themeOptions}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Accesso
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handleChange}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" id="rememberme"
                                name="rememberme"
                                checked={rememberme}
                                onChange={handleChange} />}
                            label="Rimani connesso"

                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Accedi
                        </Button>
                    </Box>
                    {error && <Alert severity="error">{error}</Alert>}
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

export default Login;