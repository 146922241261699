import * as React from 'react';
import Typography from '@mui/material/Typography';


type propsInfomazione = {
    titolo: string;
    valore: number;
    sottoTitolo?: string
}

const CardInformazione = (props: propsInfomazione) =>{
  return (
    <React.Fragment>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.titolo}
    </Typography>
      <Typography component="p" variant="h4">
        {props.valore}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {props?.sottoTitolo}
      </Typography>
    </React.Fragment>
  );
}

export default CardInformazione;