import checkResponseApi from "./apiUtils";
const apiUrl = process.env.REACT_APP_URL_API;


export const getTrattative = async (idLead: number): Promise<Trattativa[]> => {
    const response = await fetch(`${apiUrl}/trattativa?leadid=${idLead}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore API');
    }
    return await response.json();
};
export const getAllTrattative = async (dataInizio: String, dataFine: String): Promise<Trattativa[]> => {
    const response = await fetch(`${apiUrl}/trattativa/getalltrattative?dataInizio=${dataInizio}&dataFine=${dataFine}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore API');
    }
    return await response.json();
};

export const getTrattativa = async (idTrattativa: number): Promise<Trattativa> => {
    const response = await fetch(`${apiUrl}/trattativa/${idTrattativa}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore API');
    }
    return await response.json();
};

export const aggiungiTrattativa = async (formData: Trattativa): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/trattativa`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });

    return checkResponseApi(response, "Trattativa aggiunta!");
};

export const modificaTrattativa = async (idTrattativa:number, formData: Trattativa): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/trattativa/${idTrattativa}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });

    return checkResponseApi(response, "Trattativa modificata!");
};

export const eliminaTrattativa = async (idTrattativa: number): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/trattativa/${idTrattativa}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return checkResponseApi(response, "Trattativa eliminata!");
};