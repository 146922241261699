import { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { MonthProps } from "@aldabil/react-scheduler/views/Month";
import { WeekProps } from "@aldabil/react-scheduler/views/Week";
import { ProcessedEvent, EventActions } from "@aldabil/react-scheduler/types";  
import { DayProps } from "@aldabil/react-scheduler/views/Day";

export const traduzioni = {
    navigation: {
        month: "Mese",
        week: "Settiamana",
        day: "Giorno",
        today: "Oggi",
        agenda: "Agenda"
    },
    form: {
        addTitle: "Aggiungi Evento",
        editTitle: "Modifica Evento",
        confirm: "Conferma",
        delete: "Elimina",
        cancel: "Cancella"
    },
    event: {
        title: "Titolo",
        start: "Inizio",
        end: "Fine",
        allDay: "Tutto il giorno",
        subtitle: "Sottotitolo",
    },
    validation: {
        required: "Obbligatorio",
        invalidEmail: "Email non valida",
        onlyNumbers: "Sono accettati solo numeri",
        min: "Minimo {{min}} lettere",
        max: "Massimo {{max}} lettere"
    },
    moreEvents: "Altro...",
    loading: "Caricamento...",
    noDataToDisplay: "Nessuna dato da visualizzare"
};

export const month: MonthProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    weekStartOn: 1,
    startHour: 9,
    endHour: 20,
    navigation: true,
    disableGoToDay: false
};

export const week: WeekProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    weekStartOn: 1,
    startHour: 9,
    endHour: 21,
    step: 60,
    navigation: true,
    disableGoToDay: false
};

export const day: DayProps = {
    startHour: 9,
    endHour: 21,
    step: 60,
    navigation: true
};


// export const scheduler: SchedulerHelpers = {
//     state: {},
//     close: () => { },
//     loading(status: boolean) { },
//     onConfirm(event: ProcessedEvent, action: EventActions) {
//         return Promise.resolve(action);
//     }
// }