import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {Box,TextField, Button, Grid  } from '@mui/material';
import useStateAlertStore from '../store/stateAlertStore';
import { aggiungiStato, eliminaStato, modificaStato } from '@api/apiStati';
interface FormProps {
    stato: Stato | null;
    operazione: 'modifica' | 'aggiungi' | 'elimina';
    closeModal: () => void;
}

const StatoForm: React.FC<FormProps> = ({ stato, operazione, closeModal }) => {
    const [formData, setFormData] = useState<Stato>(stato ||
    {
        id: 0,
        nome: "",
        colore: "",
        isFixed: false
    });

    const openAlert = useStateAlertStore(state=>state.showAlert)

    const handleOperazione = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        switch (operazione) {
            case 'modifica':
                if (stato?.id)
                    modificaStato(stato?.id, formData).then(data => {
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                            closeModal()
                        }})
                    });
                break;
            case 'aggiungi':
                aggiungiStato(formData).then(data => {
                    openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                        closeModal()
                    }})
                });
                break;
            case 'elimina':
                if (stato?.id)
                    eliminaStato(stato?.id).then(data => {
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message , onClose() {
                            closeModal()
                        }})
                    })
                break;
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name as string]: value,
        }));
    };


    useEffect(function reloadStati() {
        if (stato) {
            setFormData(stato);
        }
    }, [stato])
    const isDisabled = operazione === 'elimina';
    return (
        <>
            <Box component="form" autoComplete="off" onSubmit={handleOperazione} sx={{ padding: '1em' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField id="nome" required label="Nome" variant="outlined" name="nome" value={formData.nome} onChange={handleChange} fullWidth disabled={isDisabled}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="colore" required label="Colore" variant="outlined" name="colore" value={formData.colore} onChange={handleChange} type="color" fullWidth disabled={isDisabled} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" fullWidth>Conferma  {operazione}</Button>
                    </Grid>
                </Grid>
            </Box>

            
        </>
    );
};

export default StatoForm;
