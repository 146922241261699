import { TCustomError } from "types/customError";
import checkResponseApi from "./apiUtils";
import { ERR_DUPLICATE, STATO_RICONTATTO_CLIENTE } from "@config/constant";
import { aggiungiTrattativa } from "./apiTrattative";
import useStateAlertStore from "@components/store/stateAlertStore";
import { fetchStati } from "./apiStati";
const apiUrl = process.env.REACT_APP_URL_API;

export const fetchLead = async (idLead: number): Promise<Lead> => {
    const response = await fetch(`${apiUrl}/Lead/${idLead}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il recupero');
    }
    return await response.json();
};
export const fetchLeads = async (): Promise<Lead[]> => {
    const response = await fetch(`${apiUrl}/Lead`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il recupero');
    }
    return await response.json();
};


export const aggiungiLead = async (formData: Lead): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/lead`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });
    if (!response.ok) {
        
        let errorResponse: TCustomError = await response.json();
        if (errorResponse.code == ERR_DUPLICATE && errorResponse.id) {
            debugger
            //Il server mi ha ritornato elemento duplicato quindi aggiungo un nuovo stato
            const stati = await fetchStati();
            const idStatoDaRichiamare = stati.filter((stato) => stato.nome == STATO_RICONTATTO_CLIENTE)[0].id;
            const trattativa: Trattativa = {
                id: 0,
                statoId: idStatoDaRichiamare,
                note: "Nuovo ricontatto da parte del cliente" ,
                leadId: errorResponse.id,
                completato: false
            }
            return await aggiungiTrattativa(trattativa);
        }
    }
    return checkResponseApi(response, "Lead aggiunto!");
};

export const modificaLead = async (idlead: number, formData: Lead): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/lead/${idlead}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });
    return checkResponseApi(response, "Lead modificato!");
};

export const eliminaLead = async (idlead: number): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/lead/${idlead}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },

    });
    return checkResponseApi(response, "Lead eliminato!");
};