
export const VALORI_SITUAZIONE: ReadonlyArray<TSituazione> = [
    {nome: "Aperto", colore: "#0000e6" },
    {nome: "In conclusione", colore: "#71c94b" },
    {nome: "Concluso", colore: "#2E7D32" },
    {nome: "Sospeso", colore: "#cc9900" },
    {nome: "Disdetto", colore: "#cc0000" },
    {nome: "Inadatto", colore: "#2F2F2F" }
] as const;

export const LIVELLO_PRIORITA: ReadonlyArray<string> = [
    "Bassa",
    "Media",
    "Alta",
] as const;

export const USER_ADMIN: string = "Admin";
export const ROLE_VENDITORE: string = "Venditore";
export const ROLE_ADMIN: string = "Amministratore";


export const STATO_RICONTATTO_CLIENTE: string = "Ricontatto dal cliente";


export const ERR_DUPLICATE: string = "ERR_DUPLICATE";

