import { useState, useEffect } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import AuthorizeView from "@components/Authorization/AuthorizeView"
import CardInformazione from '@components/object/CardInformazione';
import Calendario from '@components/Calendario/Calendario';
import { fetchLeads } from '@api/apiLeads';
import { fetchUtenti } from '@api/apiUtenti';
import { USER_ADMIN } from '@config/constant';
import paper from 'styles/Paper.module.css'

const Home: React.FC = () => {

    const [leads, setLeads] = useState<Lead[]>([]);
    const [users, setUsers] = useState<Utente[]>([]);

    useEffect(() => {
        fetchLeads().then(data => setLeads(data));
        fetchUtenti().then(data => setUsers(data));
    }, []);
    return (
        <AuthorizeView>
            <div className='container'>
                <div className='row'>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={2} lg={2}>
                                <Paper className={paper.style}>
                                    <CardInformazione titolo='Lead Generati' valore={leads.length} sottoTitolo='Ad oggi'></CardInformazione>
                                </Paper>
                            </Grid>

                            {users.filter((utente) => utente.nome != USER_ADMIN).map((utente, index) => {
                                return (
                                    <Grid item xs={12} md={2} lg={2} key={index}>
                                        <Paper className={paper.style}>
                                            <CardInformazione titolo={`Lead di ${utente.nome}`} valore={leads.filter((lead) => lead.utenteId == utente.id).length} sottoTitolo='Ad oggi'></CardInformazione>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper className={paper.style}>
                                    <CardInformazione titolo='Nuovi contatti' valore={leads.filter((lead) => lead.utente == null).length} ></CardInformazione>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Calendario />
                    </Container>
                </div>
            </div>
        </AuthorizeView>
    );
}

export default Home;