import { convertToUtc } from '@utils/convertToUtc';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';

export const aggiungiEventoCalendarioGoogle = (event?: ProcessedEvent, trattativa?: Trattativa) => {
    if (event){
        const eventTitle = encodeURIComponent(event.title);
        const startDateTime = convertToUtc(event.start); // UTC time
        const endDateTime = convertToUtc(event.end); // UTC time
        const eventDetails = encodeURIComponent(event.nota + '\n\nCellulare: ' + event.cellulare);
        const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${startDateTime}/${endDateTime}&details=${eventDetails}`;
        window.open(googleCalendarUrl, '_blank');
    }else if (trattativa){
        const eventTitle = encodeURIComponent(trattativa.lead?.azienda + ": " + trattativa.stato?.nome || "");
        const startDateTime = convertToUtc(new Date(trattativa.dataReminder)); // UTC time
        const endDateTime = convertToUtc(new Date(trattativa.dataReminder)); // UTC time
        const eventDetails = encodeURIComponent(trattativa.note + '\n\nCellulare: ' + trattativa.lead?.cellulare);
        const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${startDateTime}/${endDateTime}&details=${eventDetails}`;
        window.open(googleCalendarUrl, '_blank');
    }
    
}