import { useState } from "react";
import { TextField, Button, DialogActions, Grid, FormControlLabel, Switch } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import useTrattativeStore from "@components/store/trattativeStore";
import useCalendarioStore from "@components/store/calendarioStore";
 
import type {
    ProcessedEvent,
    SchedulerHelpers
} from "@aldabil/react-scheduler/types";

interface CustomEditorProps {
    scheduler: SchedulerHelpers;
}

const CustomEditor = ({ scheduler }: CustomEditorProps) => {
    const event = scheduler.edited;

    const [eventoCompletato, setEventoCompletato] = useState(event?.completato);
    const {updateTrattativa, fetchTrattativeById} = useTrattativeStore(state => state);
    const {aggiornaEvento} = useCalendarioStore(state => state);

    const handleChange = () => {
        setEventoCompletato(!eventoCompletato);
    };
    const handleSubmit = async () => {
        try {
            scheduler.loading(true);

            let trattativa: Trattativa = await fetchTrattativeById(Number(event?.event_id));
            trattativa = {
                ...trattativa,
                completato: !event?.completato
            }
            await updateTrattativa(Number(event?.event_id), trattativa);

                if(scheduler.edited){
                    const eventoDaModficare: ProcessedEvent = scheduler.edited;
                    const eventoModificato: ProcessedEvent = {
                        ...eventoDaModficare,
                        completato: !eventoDaModficare.completato
                    };
                    aggiornaEvento(eventoModificato);
                }
                
            // scheduler.onConfirm(added_updated_event, "edit");
            scheduler.close();
            
        } finally {
            scheduler.loading(false);
        }
    };
    return (
        <div>
            <div style={{ padding: "1rem" }}>
                <p>Definisci l'evento come completato</p>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={<Switch color="primary" checked={eventoCompletato} value={eventoCompletato} name="utenteAbilitato" onChange={handleChange} />}
                            label="Completato"
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>
            </div>
            <DialogActions>
                <Button onClick={scheduler.close}>Annulla</Button>
                <Button onClick={handleSubmit}>Conferma</Button>
            </DialogActions>
        </div>
    );
};

export default CustomEditor;