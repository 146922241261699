import React, { useState, useEffect, createContext } from 'react';
import { Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { ROLE_ADMIN, ROLE_VENDITORE, USER_ADMIN } from '@config/constant';


export const UserContext = createContext({});

interface User {
    email: string;
    nome: string;
    ruolo: string;
    utenteAbilitato: boolean
}

export default function AuthorizeView({ children }: { children: React.ReactNode }) {
    const apiUrl = process.env.REACT_APP_URL_API;
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true); // add a loading state
    let emptyuser: User = { email: "", nome: "", ruolo: "", utenteAbilitato: false };

    const [user, setUser] = useState(emptyuser);


    useEffect(() => {
        let retryCount = 0; // initialize the retry count
        let maxRetries = 10; // set the maximum number of retries
        let delay: number = 1000; // set the delay in milliseconds

        // define a delay function that returns a promise
        function wait(delay: number) {
            return new Promise((resolve) => setTimeout(resolve, delay));
        }

        // define a fetch function that retries until status 200 or 401
        async function fetchWithRetry(url: string, options: any): Promise<Response> {
            try {
                let response = await fetch(url, options);
                if (response.status == 200) {
                    let j: any = await response.json();
                    let ruolo = "";
                    if(j.nome != USER_ADMIN){
                      ruolo = ROLE_VENDITORE;
                    }else{
                      ruolo = ROLE_ADMIN;
                    }
                    setUser({ email: j.email, nome: j.nome, ruolo: ruolo, utenteAbilitato: j.utenteAbilitato });
                    if(j.utenteAbilitato)
                        setAuthorized(true);
                    else    
                        setAuthorized(false);
                    return response; // return the response
                } else if (response.status == 401) {
                    return response; // return the response
                } else {
                    throw new Error("" + response.status);
                }
            } catch (error) {
                retryCount++;
                if (retryCount > maxRetries) {
                    throw error;
                } else {
                    // wait for some time and retry
                    await wait(delay);
                    return fetchWithRetry(url, options);
                }
            }
        }

        // call the fetch function with retry logic
        fetchWithRetry(`${apiUrl}/pingauth`, {
            method: "GET",
            credentials: "include",
        })
            .catch((error) => {
                // handle the final error
                console.log(error.message);
            })
            .finally(() => {
                setLoading(false);  // set loading to false when the fetch is done
            });
    }, []);


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress size={100}
                 sx={{       
                    width: '120px'
                  }} />
            </div>
        );
    }
    else {
        if (authorized && !loading) {
            return (
                <>
                    <UserContext.Provider value={user}>{children}</UserContext.Provider>
                </>
            );
        } else {
            return (
                <>
                    <Navigate to="/login" />
                </>
            )
        }
    }

}
