import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Box, TextField, Button, InputLabel, FormControl, Grid, Typography, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fetchStati } from '@api/apiStati';
import useStateAlertStore from '../store/stateAlertStore';
import useTrattativeStore from '@components/store/trattativeStore';

interface TrattativaFormProps {
  leadID: number;
  operazioneTrattativa: TOperazione;
  trattativaID?: number;
  closeModal: () => void;
}
const TrattativaForm: React.FC<TrattativaFormProps> = ({ leadID, operazioneTrattativa, trattativaID, closeModal }) => {
  const showAlert = useStateAlertStore(state => state.showAlert)

  const [formData, setFormData] = useState<Trattativa>(
    {
      id: 0,
      dataReminder: null,
      note: '',
      leadId: leadID,
      completato: false,
    });
  const [listaStati, setListaStati] = useState<Stato[]>([]);

  const { addTrattativa, fetchTrattativeById, updateTrattativa } = useTrattativeStore(state => state);

  const handleChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: string }> | SelectChangeEvent<string | number>) => {
    let { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name as string]: value,
    }));
  };

  const handleOperazione = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (operazioneTrattativa.operazione) {
      case 'modifica':
        if (trattativaID) {
          updateTrattativa(trattativaID, formData)
            .then((res: TResponseStatus) => {
              showAlert({
                isOpen: true, severity: res.error ? 'error' : 'success', message: res.message, onClose() {
                  closeModal()
                }
              })
            })
        }
        break;
      case 'aggiungi':
        addTrattativa(formData)
          .then((res: TResponseStatus) => {
            showAlert({
              isOpen: true, severity: res.error ? 'error' : 'success', message: res.message, onClose() {
                closeModal()
              }
            })
          })
        break;
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      const stati = await fetchStati();
      setListaStati(stati);
      if (operazioneTrattativa.operazione != 'aggiungi' && trattativaID) {
        const trattativa = await fetchTrattativeById(trattativaID); //da Cambiare
        setFormData(trattativa);
      }
    };

    fetchData();
  }, [operazioneTrattativa.operazione]);

  return (

    <Box component="form" autoComplete="off" onSubmit={handleOperazione} sx={{ padding: '1em' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' id="modal-modal-title" component="h2">{operazioneTrattativa.operazione} stato trattativa lead {leadID}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField id="note" label="Nota Trattativa" variant="outlined" name="note" value={formData.note} onChange={handleChange} fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <TextField id="dataReminder" InputLabelProps={{ shrink: true }} label="Ricordami il" variant="outlined" name="dataReminder" type='datetime-local' value={formData.dataReminder} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="stato-select-label">Stato</InputLabel>
            <Select name="statoId"
              value={formData.statoId == 0 || formData.statoId == undefined ? "" : formData.statoId}
              labelId="stato-select-label" onChange={handleChange}
              id="stato-select" label="Stato" required>
              {listaStati.map(stato => {
                return (
                  <MenuItem key={stato.id} value={stato.id}>{stato.nome}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" type="submit" fullWidth>Conferma {operazioneTrattativa.operazione}</Button>
        </Grid>
      </Grid>
    </Box>
  );
};


export default TrattativaForm;
