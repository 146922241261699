import { useState, useEffect } from 'react';
import {Typography, Button, Stack,Paper } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardFonte from '@components/Fonti/DashboardFonte';
import Aside from '@components/object/Aside';


import { fetchFonti } from '@api/apiFonti';
const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'nome', headerName: 'Nome', width: 200 },
];

const Fonti: React.FC = () => {
    const [fonti, setFonti] = useState<Fonte[]>([]);
    const [selectedID, setSelectedID] = useState(null);
    const [idRigaSelezionata, setidRigaSelezionata] = useState(null);
    const [bottoniDisattivati, setBottoniDisattivati] = useState(true);
    const [operazione, setOperazione] = useState<TOperazione>({ openModal: false });

    const handleClickRiga: GridEventListener<'rowClick'> = (rigaSelezionata) => {
        const idRiga = rigaSelezionata.row.id;
        setSelectedID(idRiga);
        setidRigaSelezionata(idRiga);
        setBottoniDisattivati(false);

    };

    const openCreateModal = () => {
        setSelectedID(null);
        setOperazione({ openModal: true, operazione: 'aggiungi' })
    }

    const openEditModal = () => {
        setOperazione({ openModal: true, operazione: 'modifica' });
    }

    const openDeleteModal = () => {
        setOperazione({ openModal: true, operazione: 'elimina' });
    }

    useEffect(function initListaFonti() {
        fetchFonti().then(data => setFonti(data));
    }, [operazione.openModal]);


    const handleApriDrawer = () => {
        setOperazione({ openModal: true });
    };

    const closeModal = () => {
        setOperazione({ openModal: false });
        setSelectedID(idRigaSelezionata);
    };
    
    return (
        <>

            <Typography variant='h3'>Lista delle fonti</Typography>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ m: 5 }}>
                <Button variant="contained" onClick={openCreateModal}> <NoteAddIcon /></Button>
                <Button variant="contained" onClick={openEditModal} disabled={bottoniDisattivati}> <SettingsIcon /></Button>
                <Button variant="contained" onClick={openDeleteModal} disabled={bottoniDisattivati} > <DeleteIcon /></Button>

            </Stack>
            <Paper elevation={0} >
                <div style={{ width: '100%' }}>
                    <DataGrid rows={fonti} columns={columns} onRowClick={handleClickRiga} localeText={itIT.components.MuiDataGrid.defaultProps.localeText} />
                </div>
            </Paper>
            {
                operazione?.operazione &&
                <Aside zIndex={1202} direction='right' operazione={operazione?.operazione} onOpen={handleApriDrawer} onClose={closeModal} isOpen={operazione.openModal} modello='fonte'>
                    <DashboardFonte id={selectedID} operazione={operazione?.operazione} closeModal={closeModal}/>
                </Aside>
            }

        </>
    )
}

export default Fonti;