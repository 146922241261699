import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fetchFonti } from '@api/apiFonti';
import { fetchUtenti } from '@api/apiUtenti';
import { Box, TextField, Button, MenuItem, RadioGroup, Radio, FormControlLabel, FormControl, InputLabel, Grid, Link } from '@mui/material';
import useStateAlertStore from '../store/stateAlertStore';
import { VALORI_SITUAZIONE, LIVELLO_PRIORITA } from '@config/constant';
import useLeadsStore from '@components/store/leadsStore';
import LaunchIcon from '@mui/icons-material/Launch';
interface LeadFormProps {
  leadID: number,
  operazione: 'modifica' | 'aggiungi' | 'elimina' | "";
  closeModal: () => void;
}
const LeadForm: React.FC<LeadFormProps> = ({ leadID, operazione, closeModal }) => {

  const [formData, setFormData] = useState<Lead>(
    {
      id: 0,
      azienda: "",
      nomeCognome: "",
      email: "",
      cellulare: "",
      citta: "",
      sitoWeb: "",
      fonteId: undefined,
    });
  const [listaFonti, setListaFonti] = useState<Fonte[]>([]);
  const [listaVenditori, setListaVenditori] = useState<Utente[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const fonti = await fetchFonti();
      const venditori = await fetchUtenti();
      setListaFonti(fonti);
      setListaVenditori(venditori);

      if (operazione !== 'aggiungi') {
        const lead = await leadStore.fetchLead(leadID);
        setFormData(lead);
      }
    };

    fetchData();
  }, [operazione, leadID]);


  const showAlert = useStateAlertStore(state => state.showAlert)
  const leadStore = useLeadsStore(state => state);

  const handleChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent<string | number>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name as string]: value,
    }));
  };

  const handleOperazione = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (operazione) {
      case 'modifica':
        leadStore.updateLead(leadID, formData)
          .then((res: TResponseStatus) => {
            showAlert({ isOpen: true, severity: res.error ? 'error' : 'success', message: res.message, onClose() {
                closeModal()
            }})
          })
        break;
      case 'aggiungi':
        leadStore.addLead(formData)
          .then((res: TResponseStatus) => {
            showAlert({ isOpen: true, severity: res.error ? 'error' : 'success', message: res.message, onClose() {
              closeModal()
          }})
          })
        break;
      case 'elimina':
        leadStore.deleteLead(leadID)
          .then((res: TResponseStatus) => {
            showAlert({ isOpen: true, severity: res.error ? 'error' : 'success', message: res.message, onClose() {
              closeModal()
          }})
          })
        break;
    }
  };
  const isDisabled = operazione === 'elimina';

  return (
    <Box component="form" autoComplete="off" onSubmit={handleOperazione} sx={{ flexGrow: 1, padding: '1em' }}>

      <Grid container spacing={2}>

        {operazione != "aggiungi" &&

          <Grid item xs={12}>
            <RadioGroup
              row
              name="situazione"
              onChange={handleChange}
              value={formData.situazione || ""}
            >
              {VALORI_SITUAZIONE.map((valore) => (
                <FormControlLabel value={valore.nome} control={<Radio />} label={valore.nome} disabled={isDisabled} sx={{
                  '& .MuiFormControlLabel-label': {
                    color: valore.colore,
                  },
                }} />
              ))}
            </RadioGroup>
          </Grid>
        }
        <Grid item xs={12}>
          <TextField id="azienda" required label="Azienda" variant="outlined" name="azienda" value={formData.azienda} onChange={handleChange} fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="nomeCognome" required label="Nome e Cognome" variant="outlined" name="nomeCognome" value={formData.nomeCognome} onChange={handleChange} fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="cellulare" required label="Cellulare" variant="outlined" name="cellulare" value={formData.cellulare} onChange={handleChange} fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="email" required label="Email" variant="outlined" name="email" value={formData.email} onChange={handleChange} type='email' fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="citta" label="Città" variant="outlined" name="citta" value={formData.citta} onChange={handleChange} fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={10}>
          <TextField id="sitoWeb" label="Sito Web" variant="outlined" name="sitoWeb" value={formData.sitoWeb} onChange={handleChange} fullWidth disabled={isDisabled} />
        </Grid>
        <Grid item xs={2}>
          {operazione != 'aggiungi' &&
            <Link href={formData.sitoWeb} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Button variant="contained" size="large">
                <LaunchIcon />
              </Button>
            </Link>
          }
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth >
            <InputLabel id="fonte-select-label">Fonte*</InputLabel>
            <Select name="fonteId" value={formData.fonteId || ""} label="Fonte" onChange={handleChange} labelId="fonte-select-label" disabled={isDisabled} required>
              {listaFonti.map(fonte => {
                return (
                  <MenuItem key={fonte.id} value={fonte.id}>{fonte.nome}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="venditore-select-label">Venditore</InputLabel>
            <Select name="utenteId" value={formData.utenteId || ""} label="Venditore" onChange={handleChange} labelId="venditore-select-label" disabled={isDisabled}>
              {listaVenditori.map(venditore => {
                return (
                  <MenuItem key={venditore.id} value={venditore.id}>{venditore.nome}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="priorita-select-label">Priorità</InputLabel>
            <Select name="livelloPriorita" value={formData.livelloPriorita || ""} label="Priorità" onChange={handleChange} labelId="priorita-select-label" disabled={isDisabled}>
              {LIVELLO_PRIORITA.map((valore) => (
                <MenuItem value={valore}>{valore}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" type="submit" fullWidth>Conferma {operazione}</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadForm;
