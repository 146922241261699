import {create} from 'zustand';
import { TAlertStore, TAlert } from '../../types/stateAlert';


const useStateAlertStore = create<TAlertStore>((set,get) => ({
    message: '',
    severity: 'info', // e.g., 'info', 'warning', 'error', etc.
    duration: 3000, // default duration in milliseconds
    isOpen: false,
    setMessage: (message: TAlertStore['message']) => set({ message }),
    setSeverity: (severity:  TAlertStore['severity']) => set({ severity }),
    setDuration: (duration: TAlertStore['duration']) => set({ duration }),
    setIsOpen: (isOpen:  TAlertStore['isOpen']) => set({ isOpen }),
    showAlert: (ops: TAlert) => {
      set({ ...ops, isOpen: true });
      if (ops.onClose && get().severity == 'success') {
        ops.onClose(); 
      }
      setTimeout(() => {
        set({ isOpen: false });
        
      }, ops.duration ?? get().duration);
    }
  }));
  
  export default useStateAlertStore;