import { useState, useEffect, ChangeEvent } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {Button, RadioGroup, Radio, MenuItem, TextField,
     FormControlLabel, FormControl, InputLabel, Grid } from '@mui/material';
import { fetchFonti } from '@api/apiFonti';
import { fetchUtenti } from '@api/apiUtenti';
import { VALORI_SITUAZIONE } from '@config/constant';
import useLeadsStore from '@components/store/leadsStore';


const Filtri: React.FC= () => {

    const [listaFonti, setlistaFonti] = useState<Fonte[]>([]);
    const [listaUtenti, setlistaUtenti] = useState<Utente[]>([]);

    const {selectedFilter, setSelectedFilter, removeAllFilter} = useLeadsStore(state => state);

    const handleChangeFiltri = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number>) => {
        const { name, value } = e.target;
        if(value != ""){
            setSelectedFilter({
            ...selectedFilter,
            [name]: value,
            });
        }
        else{
            const propertyToDelete = name as keyof TFiltri;
            delete selectedFilter[propertyToDelete]
            setSelectedFilter({
            ...selectedFilter,
            });
        }

    };

    useEffect(function fillFiltri() {
        fetchFonti().then(data => setlistaFonti(data));
        fetchUtenti().then(data => setlistaUtenti(data));
    }, [])

    
    return (
        <>
            <Grid container spacing={2}>
                <Button variant="contained" onClick={removeAllFilter} sx={{ m: 1, minWidth: 250}}>Vedi tutte le lead</Button>
                <RadioGroup
                    name="situazione"
                    onChange={handleChangeFiltri}
                    value={selectedFilter.situazione || ""}
                >
                    {VALORI_SITUAZIONE.map((valore) => (
                        <FormControlLabel value={valore.nome} control={<Radio />} label={valore.nome} sx={{
                            '& .MuiFormControlLabel-label': {
                                color: valore.colore,
                            },
                        }} />
                    ))}
                </RadioGroup>
                <TextField label="Ricerca per ID" variant="filled" name="id" onChange={handleChangeFiltri} sx={{ m: 1, minWidth: 250}} value={selectedFilter?.id ?? ''} />
                <FormControl variant="filled" sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="fonte-select-label">Fonte</InputLabel>
                    <Select name="fonteId" value={selectedFilter.fonteId ?? ''} label="Fonte" onChange={handleChangeFiltri} labelId="fonte-select-label" >
                        <MenuItem value="">Tutte</MenuItem>
                        {listaFonti.map(fonte => {
                            return (
                                <MenuItem key={fonte.id} value={fonte.id}>{fonte.nome}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="venditore-select-label">Venditore</InputLabel>
                    <Select name="venditoreId" value={selectedFilter.venditoreId ?? ''} label="Venditore" onChange={handleChangeFiltri} labelId="venditore-select-label" >
                        {listaUtenti.map(utente => {
                            return (
                                <MenuItem key={utente.id} value={utente.id}>{utente.nome}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <TextField label="Ricerca per Nome" variant="filled" name="nomeCognome" onChange={handleChangeFiltri} sx={{ m: 1, minWidth: 250 }} value={selectedFilter?.nomeCognome ?? ''}/>
                <TextField label="Ricerca per Denominazione" variant="filled" name="azienda" onChange={handleChangeFiltri} sx={{ m: 1, minWidth: 250}} value={selectedFilter?.azienda ?? ''}/>
                <TextField label="Ricerca per Cellulare" variant="filled" name="cellulare" onChange={handleChangeFiltri} sx={{ m: 1, minWidth: 250}} value={selectedFilter?.cellulare ?? ''}/>
                <TextField label="Ricerca per Email" variant="filled" name="email" onChange={handleChangeFiltri} sx={{ m: 1, minWidth: 250}} value={selectedFilter?.email ?? ''}/>
            </Grid>
        </>
    )
}


export default Filtri
