import { create } from 'zustand';
import { TTrattativeStore } from '../../types/Trattative';
import { getTrattative, aggiungiTrattativa, eliminaTrattativa, getTrattativa, modificaTrattativa } from '@api/apiTrattative';

const useTrattativeStore = create<TTrattativeStore>((set, get) => ({
    trattative: [] as Array<Trattativa>,
    fetchTrattativeByLead: async (idLead: number) => {
        await getTrattative(idLead)
            .then((data) => {
                set({
                    trattative: data
                })
            })
            .catch() //error handler per renderizzare un componente di errrore
    },
    fetchTrattativeById: async (idTrattativa: number): Promise<Trattativa> => {
        const response: Trattativa = await getTrattativa(idTrattativa)
            .then((data) => {
                return data;
            })
            .catch()
        return new Promise(resolve => resolve(response));
    },
    addTrattativa: async (trattativa: Trattativa): Promise<TResponseStatus> => {
        const response: TResponseStatus = await aggiungiTrattativa(trattativa)
        if (!response.error) {
            const tempTrattative = get().trattative;
            tempTrattative.unshift(response.data)
            set({ trattative: tempTrattative })
        }

        return new Promise(resolve => resolve(response));
    },
    updateTrattativa: async (trattativaID: number, trattativa: Trattativa): Promise<TResponseStatus> => {
        const response: TResponseStatus = await modificaTrattativa(trattativaID, trattativa)
        if(!response.error){
     
            get().fetchTrattativeByLead(trattativa.leadId);
        }
        return new Promise(resolve => resolve(response));
    },
    deleteTrattativa: async (id: number): Promise<TResponseStatus> => {
        const response: TResponseStatus = await eliminaTrattativa(id)
        if (!response.error) {
            const tempTrattative = get().trattative.filter(trattativa => trattativa.id != id);
            set({ trattative: tempTrattative })
        }

        return new Promise(resolve => resolve(response));
    },
    

}));

export default useTrattativeStore;
