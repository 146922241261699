import { Chip } from '@mui/material';
import { getBackgroundColor } from '@utils/getBackgroundcolor';
type Props = {
    nome: string,
    colore: string
}

const LabelColorata: React.FC<Props> = ({nome, colore}) =>{
  return (
    <>
          {nome &&
              <Chip label={nome} sx={{ backgroundColor: colore, fontWeight: 800, mr: 1, color: getBackgroundColor(colore) }} />
          }
    </>
  );
}

export default LabelColorata;