
async function checkResponseApi(response: Response, onSuccessMessage: string): Promise<TResponseStatus> {
    
    let result: TResponseStatus = {
        error: true,
        message: "",
    };
    
    if (!response.ok) {
        const errorData = await response.text();
        result.error = true;
        result.message = response.status + ": " + errorData;
    }            
    else{
        result.error = false;
        result.message = onSuccessMessage
        if(response.status != 204){
            result.data = await response.json();
        }
        
    }

    return new Promise(resolve => resolve(result));
}

export default checkResponseApi;
