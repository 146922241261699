import { useState, useEffect } from 'react';
import StatoForm from './StatoForm';

interface Parametri {
    id: number | null;
    operazione: 'modifica' | 'aggiungi' | 'elimina';
    closeModal: () => void;
}

const apiUrl = process.env.REACT_APP_URL_API;
const DashboardStato: React.FC<Parametri> = ({id, operazione, closeModal}) =>{
    const [selectedStato, setSelectedStato] = useState<Stato | null>(null);
    
    const fetchStato =async () => {
        fetch(apiUrl + `/stato/${id}`)
            .then(response => response.json())
            .then(data => setSelectedStato(data))
            .catch(error => console.error('Errore:', error));
    }

    useEffect(function init(){
        if(id){
          fetchStato();
        }
    }, [id]);

    return (
        <>
            <div>
                <StatoForm stato={selectedStato} operazione={operazione} closeModal={closeModal}></StatoForm>
            </div>
        </>
    )
}
export default DashboardStato;
