import { useState, useEffect, useRef } from 'react';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import { Button, Stack, Paper, Grid, Tooltip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import LabelGG from '@components/object/LabelGG';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Aside from '@components/object/Aside';
import LeadForm from '@components/Leads/LeadForm';
import Filtri from '@components/Leads/Filtri';
import LabelColorata from '@components/object/LabelColorata';
import { VALORI_SITUAZIONE } from '@config/constant';
import useLeadsStore from '@components/store/leadsStore';
import { estraiMaiuscole } from '@utils/estraiMaiuscole';
import { format } from 'date-fns';
import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useNavigate, useLocation } from "react-router-dom";
import FiltriAttivi from '@components/Leads/FiltriAttivi';

type TOperazione = {
    openModal: boolean;
    operazione: 'modifica' | 'aggiungi' | 'elimina' | "";
}
const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'azienda', headerName: 'Azienda', width: 150, valueGetter: (value, row) => row?.azienda },
    { field: 'nomeCognome', headerName: 'Nome e Cognome', width: 150, valueGetter: (value, row) => row?.nomeCognome },
    { field: 'cellulare', headerName: 'Cellulare', width: 130, valueGetter: (value, row) => row?.cellulare },
    {
        field: 'dataArrivo', headerName: 'Data Arrivo', width: 130, valueGetter: (value, row) => row.dataArrivo,
        renderCell: (params: GridRenderCellParams<any, String>) => (
            <>
                {format(new Date(params.row?.dataArrivo), 'dd/MM/yyyy')}
            </>
        )
    },
    {
        field: 'nomeStato',
        headerName: 'Ultimo Stato Trattativa',
        width: 200,
        valueGetter: (value, row) => row?.ultimoStatoTrattativa,
        renderCell: (params: GridRenderCellParams<any, Stato>) => (
            <>
                <LabelColorata nome={params.value?.nome || ""} colore={params.value?.colore || ""} />
            </>
        )
    },
    {
        field: 'ultimoContatto',
        headerName: 'Non ti senti da',
        width: 100,
        valueGetter: (value, row) => row.dataUltimaTrattativa,
        renderCell: (params: GridRenderCellParams<any, String>) => (
            <>
                <LabelGG inputDate={params.row?.dataUltimaTrattativa} />
            </>
        )
    },
    {
        field: 'fattaProposta',
        headerName: 'Proposta?',
        width: 80,
        renderCell: (params: GridRenderCellParams<any, String>) => {
            return (
                <>
                    {params.value &&
                        <LabelColorata nome={"Sì" || ""} colore={"#2E7D32"} />
                    }
                </>
            )
        }
    },
    {
        field: 'venditore',
        headerName: 'Venditore',
        width: 150,
        valueGetter: (value, row) => {
            return row.nomeUtente || 'NESSUNO';
        },
        renderCell: (params: GridRenderCellParams<any, String>) => {
            const nomeUtente = params.row?.nomeUtente || 'NESSUNO';
            const lettereMaiuscole = estraiMaiuscole(nomeUtente);
            return (
                <>
                    {nomeUtente !== 'NESSUNO' && (
                        <LabelColorata nome={lettereMaiuscole || ""} colore={"#2F2F2F"} />
                    )}
                    {nomeUtente}
                </>
            )
        }
    },
    { field: 'fonte', headerName: 'Fonte', width: 150, valueGetter: (value, row) => row.fonte.nome },
    { field: 'livelloPriorita', headerName: 'Priorita', width: 80, valueGetter: (value, row) => row?.livelloPriorita },
    {
        field: 'situazione', headerName: 'Situazione', width: 120, valueGetter: (value, row) => VALORI_SITUAZIONE.filter((valore) => valore.nome == row.situazione)[0],
        renderCell: (params: GridRenderCellParams<any>) => (
            <>
                <LabelColorata nome={params.value?.nome || ""} colore={params.value?.colore || ""} />
            </>
        )
    },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Leads: React.FC = () => {
    const navigate = useNavigate();
    const [bottoniDisattivati, setbottoniDisattivati] = useState(true);
    const [operazione, setOperazione] = useState<TOperazione>({ openModal: false, operazione: "" });
    const { fetchLeads, leads, filteredLeads, selectedID, setselectedID } = useLeadsStore(state => state);
    const location = useLocation();
    useEffect(() => {
        if (location.state?.selectedID) {
            setselectedID(location.state.selectedID);
        }
    }, [location.state, setselectedID]);

    useEffect(() => {
        fetchLeads();
    }, [])

    useEffect(function attivaBottoni() {
        if (selectedID > 0){
            setbottoniDisattivati(false);
        }else{
            setbottoniDisattivati(true);
        }
    }, [selectedID]);


    const clickTrattative = () => {
        navigate("/gestione-trattative/" + selectedID)
    };

    const handleClickRiga: GridEventListener<'rowClick'> = (rigaSelezionata) => {
        const idLead = rigaSelezionata.row.id;
        setselectedID(idLead);
    };

    const apriModal = (operazione: TOperazione["operazione"]) => {
        setOperazione({ openModal: true, operazione: operazione });
    }

    const closeModal = () => {
        setOperazione({ openModal: false, operazione: "" });
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Filtri />
            </Grid>
            <Grid item xs={10}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ m: 1 }}>
                    <Tooltip title="Aggiungi">
                        <Button variant="contained" onClick={() => apriModal("aggiungi")}> <NoteAddIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Modifica">
                        <Button variant="contained" onClick={() => apriModal("modifica")} disabled={bottoniDisattivati}> <SettingsIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Elimina">
                        <Button variant="contained" onClick={() => apriModal("elimina")} disabled={bottoniDisattivati} > <DeleteIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Vai alle trattative">
                        <Button variant="contained" onClick={clickTrattative} disabled={bottoniDisattivati} > <VisibilityIcon /></Button>
                    </Tooltip>
                </Stack>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ mt: 3, mb: 3 }}>
                    <FiltriAttivi />
                </Stack>

                <Paper elevation={0} style={{ height: '700px', width: '100%', overflowY: 'auto' }}>
                    {leads &&
                        <DataGrid rows={filteredLeads} columns={columns} onRowClick={handleClickRiga} localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => {
                                return params.row.venditore === null ? "non-assegnato" : "";
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'dataArrivo', sort: 'desc' }],
                                },
                                pagination: { paginationModel: { pageSize: 100 } },
                            }}
                            rowSelectionModel={selectedID}
                        />
                    }
                </Paper>
                {operazione.openModal &&
                    <Aside zIndex={1202} direction='right' operazione={operazione?.operazione} onOpen={() => { }} onClose={closeModal} isOpen={operazione.openModal} modello='lead'>
                        <LeadForm operazione={operazione.operazione} leadID={selectedID} closeModal={closeModal} />
                    </Aside>
                }
            </Grid>
        </Grid>

    );
};


export default Leads;
