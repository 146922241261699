// components/UserForm.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { abilitaUtente, eliminaUtente } from '../../api/apiUtenti';
import useStateAlertStore from '../store/stateAlertStore';
interface FormProps {
    utente: Utente | null;
    operazione: 'modifica' | 'aggiungi' | 'elimina';
}

const UtenteForm: React.FC<FormProps> = ({ utente, operazione }) => {
    const openAlert = useStateAlertStore(state=>state.showAlert)
    const [formData, setFormData] = useState<Utente>(utente ||
    {
        id: "",
        nome: "",
        email: "",
        utenteAbilitato: false
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, type, checked, value } = e.target as HTMLInputElement;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };


    const handleOperazione = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        switch (operazione) {
            case 'modifica':
                if(utente?.id){
                    abilitaUtente(utente.id, formData.utenteAbilitato).then(data =>{
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message })
                    });
                }
                break;

            case 'elimina':
                if(utente?.id){
                    eliminaUtente(utente.id).then(data =>{
                        openAlert({ isOpen: true, severity: data.error ? 'error' : 'success', message: data.message })
                    });
                }
                break;
        }
    };
    const isDisabled = operazione === 'elimina';
    return (
        <>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleOperazione} sx={{ padding: '1em' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h6" component="h6">
                            Nome: {utente?.nome}
                        </Typography>
                        <Typography variant="h6" component="h6">
                            Email: {utente?.email}
                        </Typography>
                        <FormControlLabel
                            control={<Switch color="primary" checked={formData.utenteAbilitato} onChange={handleChange} value={formData.utenteAbilitato} name="utenteAbilitato" />}
                            label="Abilitato"
                            labelPlacement="end"
                            disabled={isDisabled}
                        />
                    </Grid>
                   
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" fullWidth>Conferma  {operazione}</Button>
                    </Grid> 
                </Grid>
            </Box>

        </>
    );
};

export default UtenteForm;
