import { useState } from "react";
import { Avatar, Button, TextField, Box, Typography, Container, Paper } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useStateAlertStore from "@components/store/stateAlertStore";


function Register() {
    const showAlert = useStateAlertStore(state => state.showAlert)
    const apiUrl = process.env.REACT_APP_URL_API;
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "nome") setNome(value);
        if (name === "confirmPassword") setConfirmPassword(value);
    };

    // handle submit event for the form
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // validate email and passwords
        if (!email || !password || !confirmPassword) {
            showAlert({ severity: "error", isOpen: true, message: "Compila tutti i campi!" })
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            showAlert({ severity: "error", isOpen: true, message: "Inserisci un indirizzo email valido" })
        } else if (password !== confirmPassword) {
            showAlert({ severity: "error", isOpen: true, message: "Le password non coincidono" })
        } else {
            // post data to the /register api
            fetch(`${apiUrl}/Account/register`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    Nome: nome,
                }),
            })
                .then((data) => {
                    if (data.ok)
                        showAlert({ severity: "success", isOpen: true, message: "Utente registrato con successo!" })
                    else
                    showAlert({ severity: "error", isOpen: true, message: "Errore in fase di registrazione" })

                })
                .catch((error) => {
                    console.error(error);
                    showAlert({ severity: "error", isOpen: true, message: "Errore della API" })

                });
        }
    };

    return (
        <Paper>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registrazione nuovo Utente
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoComplete="Nome"
                            autoFocus
                            value={nome}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Conferma Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={confirmPassword}
                            onChange={handleChange}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Registra utente
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Paper>
    );
}

export default Register;