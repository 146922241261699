import { useState, useEffect } from 'react';
import {Typography, Button, Stack, Paper} from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Aside from '@components/object/Aside';
import { fetchUtenti } from '@api/apiUtenti';
import UtenteForm from '@components/Authorization/UtenteForm';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    {
        field: 'utenteAbilitato', headerName: 'Venditore', width: 200, valueGetter: (value, row) => {
            if (row.utenteAbilitato)
                return "ABILITATO";
            else
                return 'NON ABILITATO';
        }
    }
];

const GestioneUtenti: React.FC = () => {
    const [utenti, setUtenti] = useState<Utente[]>([]);
    const [selectedUser, setSelectedUser] = useState<Utente>();
    const [bottoniDisattivati, setBottoniDisattivati] = useState(true);
    const [operazione, setOperazione] = useState<TOperazione>({ openModal: false });

    const handleClickRiga: GridEventListener<'rowClick'> = (rigaSelezionata) => {
        setSelectedUser({ id: rigaSelezionata.row.id, email: rigaSelezionata.row.email, nome: rigaSelezionata.row.nome, utenteAbilitato: rigaSelezionata.row.utenteAbilitato })
        setBottoniDisattivati(false);
    };


    const openEditModal = () => {
        setOperazione({ openModal: true, operazione: 'modifica' });
    }

    const openDeleteModal = () => {
        setOperazione({ openModal: true, operazione: 'elimina' });
    }

    useEffect(function initListaStati() {
        fetchUtenti().then(data => setUtenti(data));
    }, [operazione.openModal]);


    const handleApriDrawer = () => {
        setOperazione({ openModal: true });
    };

    const handleChiudiDrawer = () => {
        setOperazione({ openModal: false });
    };

    return (
        <>
            <Typography variant='h3'>Lista degli utenti</Typography>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ m: 5 }}>
                <Button variant="contained" onClick={openEditModal} disabled={bottoniDisattivati}> <SettingsIcon /></Button>
                <Button variant="contained" onClick={openDeleteModal} disabled={bottoniDisattivati} > <DeleteIcon /></Button>
            </Stack>
            <Paper elevation={0} >
                <div style={{ width: '100%' }}>
                    <DataGrid rows={utenti} columns={columns} onRowClick={handleClickRiga} localeText={itIT.components.MuiDataGrid.defaultProps.localeText}  />
                </div>
            </Paper>

            {operazione?.operazione && selectedUser &&
                <Aside zIndex={1202} direction='right' operazione={operazione?.operazione} onOpen={handleApriDrawer} onClose={handleChiudiDrawer} isOpen={operazione.openModal} modello='utente'>
                    <UtenteForm utente={selectedUser} operazione={operazione?.operazione} />
                </Aside>
            }
        </>
    )
}

export default GestioneUtenti;