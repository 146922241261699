import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { fetchLead } from '@api/apiLeads';
import {Grid, Box, Stack, Button, Paper, Breadcrumbs, Link, Typography} from '@mui/material';
import CardLead from '@components/Leads/CardLead';
import StoriaTrattativa from '@components/Trattative/StoriaTrattativa';
import useTrattativeStore from '@components/store/trattativeStore';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Aside from '@components/object/Aside';
import TrattativaForm from '@components/Trattative/TrattativaForm';
import useLeadsStore from '@components/store/leadsStore';

type Params = {
    selectedID: string;
}

const DashboardTrattativa: React.FC = () => {
    const { selectedID } = useParams<Params>();
    const [numericId, setNumericId] = useState<number | null>(null);
    const [operazione, setOperazione] = useState<TOperazione>({ openModal: false });
    const [lead, setLead] = useState<Lead>();
    const [bottoneAbilitato, setBottoneAbilitato] = useState<boolean>(true);
    const [selectedIDTrattativa, setSelectedIDTrattativa] = useState<number | null>(null);

    const fetchTrattativeByLead = useTrattativeStore(state => state.fetchTrattativeByLead);
    const selectedFilter = useLeadsStore(state => state.selectedFilter);
    const selectedLeadID = useLeadsStore(state => state.selectedID);
    useEffect(() => {
        if (selectedID !== undefined) {
            const parsedId = parseInt(selectedID);
            if (!isNaN(parsedId)) {
                setNumericId(parsedId);
                fetchTrattativeByLead(parsedId);
                fetchLead(parsedId).then(data => setLead(data));
            }
        }
    }, [selectedID]);

    useEffect(() =>{ //Vedo se sta il venditore
        if(lead?.utente){
            setBottoneAbilitato(false);
        }
    }, [lead]);

    if (selectedID === undefined) {
        return <div>ID non trovato</div>;
    }

    if (numericId === null) {
        return <div>ID non è un numero valido</div>;
    }


    const openTrattativaModal = () => {
        setOperazione({
            openModal: true,
            operazione: "aggiungi"
        });
    }

    const closeModal = () => {
        setOperazione({ openModal: false });
    };

    const openModificaTrattativaModal = (trattativaID?:number) =>{
        setOperazione({ 
            openModal: true,
            operazione: "modifica",
        });
        if(trattativaID){
            setSelectedIDTrattativa(trattativaID);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} underline="hover" color="inherit" to="/leads" state={{selectedID, selectedFilter }}>
                            {`< Leads`}
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4}>
                    {lead &&
                        <CardLead lead={lead} />
                    }
                </Grid>
                <Grid item xs={8}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ m: 1 }}>
                        <Button variant="contained" onClick={openTrattativaModal} disabled={bottoneAbilitato} > <NoteAddIcon /> Aggiungi nuovo stato Trattativa</Button>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ maxHeight: 500, overflow: 'auto' }}>
                        {lead?.utenteId ? (
                            <StoriaTrattativa openTrattativaModal={openModificaTrattativaModal} />
                        ) : (
                            <Typography sx={{p:2}} variant='h5'>Devi prima assegnare un venditore per poter gestire le trattative!</Typography>
                        )}
                    </Paper>
                </Grid>
                
                {numericId && operazione.openModal &&
                    <Aside zIndex={1202} direction='right' operazione={operazione.operazione || ""} onOpen={openTrattativaModal} onClose={closeModal} isOpen={operazione.openModal} modello='trattativa'>
                        <TrattativaForm leadID={numericId} operazioneTrattativa={operazione} trattativaID={selectedIDTrattativa || undefined} closeModal={closeModal}></TrattativaForm>
                    </Aside>
                }
            </Grid>
        </Box>
    )
}

export default DashboardTrattativa;