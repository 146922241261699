import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import { getBackgroundColor } from "@utils/getBackgroundcolor";

const castTrattativeToProcessedEvent =  (trattative: Trattativa[]) => {
    let datiEvents: ProcessedEvent[] = [];
    trattative.map((evento) => {
        datiEvents.push({
            event_id: evento.id,
            title: evento.lead?.azienda + ": " + evento.stato?.nome || "",
            start: new Date(evento.dataReminder),
            end: new Date(evento.dataReminder),
            color: evento.stato?.colore,
            textColor: getBackgroundColor(evento.stato?.colore),
            editable: true,
            draggable: false,
            deletable: false,
            venditoreId: evento.lead?.utente?.id,
            venditore: evento.lead?.utente?.nome,
            nota: evento.note,
            cellulare: evento.lead?.cellulare,
            leadID: evento.leadId,
            assignee: evento.lead?.utente?.id,
            completato: evento.completato,
        })
    })
    return(datiEvents);
}

export default castTrattativeToProcessedEvent;