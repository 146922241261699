import checkResponseApi from "./apiUtils";
const apiUrl = process.env.REACT_APP_URL_API;

export const fetchUtenti = async (): Promise<Utente[]> => {
    const response = await fetch(`${apiUrl}/Account/utenti`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`, // Aggiungi l'header Authorization
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il recupero');
    }
    return await response.json();
};
export const abilitaUtente = async (idutente: string, abilitazioneUtente: boolean): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/Account/abilitautente/${idutente}?abilitazioneUtente=${abilitazioneUtente}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`, // Aggiungi l'header Authorization
        }
    });
    return checkResponseApi(response, "Stato modificato!");
};

export const eliminaUtente = async (idutente: string): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/Account/${idutente}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return checkResponseApi(response, "Utente eliminato!");
};