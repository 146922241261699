const apiUrl = process.env.REACT_APP_URL_API;

interface RegisterResponse {
    message: string;
    // altri campi di risposta, se presenti
}

interface LoginResponse {
    accessToken: string;
    email: string;
    nome: string;
}

// export const register = async (email: string, password: string): Promise<RegisterResponse> => {
//     const response = await fetch(`${apiUrl}/register`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//     });
//     if (!response.ok) {
//         throw new Error('Errore durante la registrazione');
//     }
//     return await response.json();
// };

// export const login = async (email: string, password: string, rememberme: boolean): Promise<LoginResponse> => {
//     const response = await fetch(`${apiUrl}/identity/login`, {
//         method: "POST",
//         credentials: "include",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//             email: email,
//             password: password,
//         }),
//     });
//     if (!response.ok) {
//         throw new Error('Errore durante il login');
//     }else{
        
//     }
//     return await response.json();
// };

export const info = async (accessToken: string): Promise<LoginResponse> => {
    const response = await fetch(`${apiUrl}/identity/manage/info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Aggiungi l'header Authorization
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il login');
    }
    return await response.json();
};

export const logout = async (): Promise<boolean> =>{

    const response = await fetch(`${apiUrl}/logout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: ""
    });
    if (!response.ok) {
        throw new Error('Errore durante il logout');
    }
    return await true;
};


export const login = async (rememberMe: string, email: string, password: string): Promise<Response> => {
    
    const response = await fetch(`${apiUrl}/login?${rememberMe}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password,
        }),
    });
    return await response;
};

