import LeftMenu from '@components/LeftMenu';
import { ThemeProvider, createTheme } from '@mui/material';
import Container from '@mui/material/Container';

// import { useAuth } from './AuthContext';
import AuthorizeView from '@components/Authorization/AuthorizeView';
const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#C54040',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#ebeff2',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px', // Imposta un valore per rendere i bottoni più stondati
        },
      },
    },
  },

});


export default function RootLayout({ children }: { children: React.ReactNode }) {
  // const { user } = useAuth();
  // if(!user){ return (<LoginComponent />)}
  return (
    <div style={{ minHeight: '100vh' }}>
      <ThemeProvider theme={themeOptions}>
        <AuthorizeView>
          <LeftMenu>
            <Container maxWidth={false}>
              {children}
            </Container>
          </LeftMenu>
        </AuthorizeView>
      </ThemeProvider>
    </div>
  )
}
