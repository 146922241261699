import checkResponseApi from "./apiUtils";
const apiUrl = process.env.REACT_APP_URL_API;

export const fetchStati = async (): Promise<Stato[]> => {
    const response = await fetch(`${apiUrl}/stato`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`, // Aggiungi l'header Authorization
        }
    });
    if (!response.ok) {
        throw new Error('Errore durante il recupero');
    }
    return await response.json();
};

export const prendiStato = async (idStato: number): Promise<Stato> => {
    const response = await fetch(`${apiUrl}/stato/${idStato}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return await response.json();
};

export const aggiungiStato = async (formData: Stato): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/stato`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });

    return checkResponseApi(response, "Stato aggiunto!");

};
export const modificaStato = async (idStato: number, formData: Stato): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/stato/${idStato}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
    });
    
    return checkResponseApi(response, "Stato modificato!");
};

export const eliminaStato = async (idStato: number): Promise<TResponseStatus> => {
    const response = await fetch(`${apiUrl}/stato/${idStato}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    
    return checkResponseApi(response, "Stato eliminato!");
};