import * as React from 'react';
import {Card,CardContent, Typography}  from '@mui/material';
import { format } from 'date-fns';

interface Props {
    lead: Lead
  }
const CardLead: React.FC<Props> = ({lead}) => {
// export default function CardLead(paramLead: Lead) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
        {lead.id} - {lead.azienda}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {lead.nomeCognome}
        </Typography>
        <Typography variant="body2">
          E-mail: <b>{lead.email}</b>
          <br />
        </Typography>
        <Typography variant="body2">
          Cellulare: <a href={`tel:+39${lead.cellulare}`}>{lead.cellulare}</a>
          <br />
        </Typography>
        <Typography variant="body2">
          Città: <b>{lead.citta}</b>
          <br />
        </Typography>
        <Typography variant="body2">
          Sito Web: <a href={lead.sitoWeb} target='_blank'>{lead.sitoWeb}</a>
          <br />
        </Typography>
        <Typography variant="body2">
          Fonte: <b>{lead.fonte?.nome}</b>
          <br />
        </Typography>
        <Typography variant="body2">
          Data Arrivo: <b>{format(new Date(lead.createAt), 'dd/MM/yyyy HH:mm')}</b>
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
}
export default CardLead;