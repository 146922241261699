import * as React from 'react';
import { Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip, Chip } from '@mui/material/';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { UserContext } from './AuthorizeView';
import { logout } from '../../api/apiLoginService';
import { useNavigate } from "react-router-dom";
import FaceIcon from '@mui/icons-material/Face';
import { ROLE_ADMIN } from '@config/constant';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleLogout = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const response = await logout();
    if (response) {
      window.location.reload();
    }
  };

  const handleRegister = () => {
    navigate("/register");
  }

  const handleGestioneUtenti = () => {
    navigate("/gestione-utenti");
  }

  const user: any = React.useContext(UserContext);

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Chip icon={<FaceIcon />} label={user.ruolo} color="success" />
        <Tooltip title="Impostazioni Account">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{user.nome.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> {user.nome}
        </MenuItem>
        <Divider />
        {user.ruolo == ROLE_ADMIN &&
          < MenuItem onClick={handleRegister}>
            <ListItemIcon >
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Nuovo Account
          </MenuItem>
        }

        {user.ruolo == ROLE_ADMIN &&
          <MenuItem onClick={handleGestioneUtenti}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Gestione Utenti
          </MenuItem>
        }
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
