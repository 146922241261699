import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import { EventActions, FieldProps, SchedulerHelpers, RemoteQuery, SchedulerRef } from '@aldabil/react-scheduler/types';
import { it } from 'date-fns/locale';
import { ProcessedEvent, DefaultResource } from '@aldabil/react-scheduler/types';
import {  Button, Box, Switch } from '@mui/material';
import { getAllTrattative } from '@api/apiTrattative';
import { fetchUtenti } from '@api/apiUtenti';
import { USER_ADMIN } from '@config/constant';
import { getBackgroundColor } from '@utils/getBackgroundcolor';
import { traduzioni, month, week,day } from 'types/calendario/customtypes';
import { ROLE_VENDITORE } from '@config/constant';
import RiepigoloEvento from './RiepilogoEvento';
import { convertToSQLDate } from '@utils/convertToSQLDate';
import CustomEditor from './CustomEditor';
import useCalendarioStore from '@components/store/calendarioStore';


const Calendario: React.FC = () => {
    
    // const [filtroEventiCompletati, setfiltroEventiCompletati] = useState<boolean>(false);
    
    const [utenti, setUtenti] = useState<Utente[]>([]);
    const [resources, setResources] = useState<DefaultResource[]>([]);
    const calendarRef = useRef<SchedulerRef>(null);

    useEffect(() => {
        fetchUtenti()
            .then(data => {
                setUtenti(data.filter((utente) => utente.nome != USER_ADMIN));
            }).then();
        
    }, []);

    useEffect(() => {
        fillUtenti(utenti);
    }, [utenti]);

    const fillUtenti = (utenti: Utente[]) => {
        setResources(utenti.map((utente) => {
            return {
                assignee: utente.id,
                text: utente.nome,
                subtext: ROLE_VENDITORE,
                avatar: "ABC",
                color: "#2F2F2F"
            }
        }))
    }

    const { eventiFiltrato, filtraEventi, fetchEventi, mostraTutti } = useCalendarioStore(state => state);

    // const confermaOperazione = async (event: ProcessedEvent,action: EventActions): Promise<ProcessedEvent> => {
    //     console.log("Eccomi")
    //     return new Promise((res, rej) => {
    //         res({
    //              ...event,
    //              completato: !event.completato
    //         });
        
    //     });
    // };

    const handleFiltro = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        
        // setEventiFiltrato(eventi.filter((evento) => evento.completato == filtroEventiCompletati));

        calendarRef.current?.scheduler?.handleState(filtraEventi(checked), "events");
        
    };

    return (
        <Box sx={{marginTop: 2}}>
            <Switch color="primary" checked={mostraTutti} value={mostraTutti} name="utenteAbilitato" onChange={handleFiltro} />
            <p>Mostra tutti gli eventi</p> 
            {resources.length > 0 ? (
                <Scheduler
                    ref={calendarRef}
                    view="week"
                    hourFormat="24" 
                    translations={traduzioni}
                    locale={it}
                    month={month}
                    week={week}
                    day={day}   
                    editable={false}
                    resources={resources}
                    events={eventiFiltrato}
                    resourceViewMode="tabs"
                    getRemoteEvents={fetchEventi}
                    // onConfirm={confermaOperazione}
                    customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
                    viewerExtraComponent={(fields, event)=> {
                        return (
                            <Box sx={{marginTop: 2}}>
                                <RiepigoloEvento event={event} />
                            </Box>
                        );
                    }}
                />
            ) : null}
        </Box>
    )
}
export default Calendario;
