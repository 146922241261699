import { create } from 'zustand';
import { TCalendarioStore } from '../../types/calendario/calendario';
import { getAllTrattative } from '@api/apiTrattative';
import { ProcessedEvent, RemoteQuery } from '@aldabil/react-scheduler/types';
import { convertToSQLDate } from '@utils/convertToSQLDate';
import castTrattativeToProcessedEvent from '@utils/calendario/castTrattativeToProcessedEvent';

function filtraEventiCompletati(eventi: Array<ProcessedEvent>, mostraTutti: boolean): Array<ProcessedEvent>  {
    return eventi.filter((evento) => evento.completato == mostraTutti);
}

const useCalendarioStore = create<TCalendarioStore>((set, get) => ({
    mostraTutti: false,
    eventi: [] as Array<ProcessedEvent>,
    eventiFiltrato: [] as Array<ProcessedEvent>,
    filtraEventi: (mostraTutti: boolean) => {
        let temp;
        if(mostraTutti){
            temp = get().eventi;
        }else{
            temp = filtraEventiCompletati(get().eventi, mostraTutti);
        }
        set({
            eventiFiltrato: temp,
            mostraTutti: mostraTutti
        })
        return temp;
    },
    fetchEventi: async (query: RemoteQuery): Promise<ProcessedEvent[]> => {
        const { start, end } = query;
        const startDate = convertToSQLDate(start);
        const endDate = convertToSQLDate(end);
        const data = await getAllTrattative(startDate, endDate);
        let eventiCalendario : ProcessedEvent[];
        eventiCalendario = castTrattativeToProcessedEvent(data);
        set({
            eventi: eventiCalendario
        })
        return get().filtraEventi(get().mostraTutti);
    },
    aggiornaEvento: (eventoModificato: ProcessedEvent) => {
        
        const eventiAggiornati: Array<ProcessedEvent> = get().eventi.map((evento) => {
            return evento.event_id == eventoModificato.event_id ? eventoModificato : evento;
        });
        if (eventiAggiornati.length > 0){
            set({
                eventi: eventiAggiornati
            })
        }
        
        get().filtraEventi(get().mostraTutti);
    }
}));
export default useCalendarioStore;